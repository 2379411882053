import { useEffect, useState } from "react"
import { Box, IconButton, Typography, LinearProgress } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import DescriptionIcon from '@mui/icons-material/Description';
import { apiClient } from "../helper/api"
import useAuth, { authData } from "../context/AuthProvider";
import moment from "moment";
import formatPrice from "../helper/currencyFormatter";
import { AddDocument } from "./AddDocuments";

interface Transaction {
  rowNum: number;
  invoiceNumber: string;
  insuredName: string;
  policyNumber: string;
  homeState: string;
  transactionTypeName: string;
  invoiceDate: string;
  effectiveDate: string;
  policyExpDate: string;
  grossPremium: string;
  customerpolicyid: number;
}

export const CustomerTransactions: React.FC<{ currentUser: authData | undefined }> = ({ currentUser }) => {
  // Sample data - replace with your actual data source
  const [loading, setloading] = useState(false)
  const { user } = useAuth()
  const [itemList, setItemList] = useState<Transaction[]>([])
  const [ pageSize, setPageSize] = useState(50)
  const [ pageNumber, setPageNumber] = useState(1)
  const [ totalCount, setTotalCount] = useState(0)
  const [sortModel, setSortModel] = useState([{ field: "", sort: "" }]);
  const [filterModel, setFilterModel] = useState({ items: [{ columnField: "", value: "", operatorValue: "" }] });
  const [ID, setID] = useState();
  const [openDoc, setOpenDoc] = useState(false);
  
  const token = user?.token

  useEffect(() => {
    fetchData();
  }, [pageNumber, pageSize, filterModel, sortModel])

  const fetchData = () => {
    const params = new URLSearchParams();

    params.append('pageSize', pageSize.toString() || '');
    params.append('pageNumber', pageNumber.toString() || '');
    params.append('fieldName', filterModel['items'][0]['columnField'] || '');
    params.append('value', filterModel['items'][0]['value'] || '');
    params.append('field', sortModel[0]['field'] || '');
    params.append('sort', sortModel[0]['sort'] || '');
    params.append('operators', filterModel['items'][0]['operatorValue'] || '');

    apiClient(`/customer/getTransactions?${params.toString()}`, {
      method: "GET",
      headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
      }),
    })
    .then(responseJson => {
      if (!responseJson) return

      if (responseJson.data && responseJson.data.getCustomerTransactions_Respdata.length > 0) {
          setItemList(responseJson.data.getCustomerTransactions_Respdata);
          setTotalCount(responseJson.data.totalCount)
          setloading(false);
          console.log("alldata", responseJson.data);
      } else {
          setItemList([]);
          setTotalCount(0)
          setloading(false);
      }
    })
    .catch(error => {
      setItemList([]);
      setloading(false);
    });
  };

  const handleClose = () => {
    setOpenDoc(false)
    fetchData()
  };

  const UploadDocument = (id: any) => {
    setID(id);
    setOpenDoc(true);
  }

  const columns: GridColDef[] = [
    {
      field: 'customerPolicyId', 
      hide: true,
    },
    { 
      field: 'invoiceNumber', 
      headerName: 'Invoice Number',
      flex: 1,
      minWidth: 120,
    },
    { 
      field: 'insuredName', 
      headerName: 'Insured Name',
      flex: 1.2,
      minWidth: 150,
    },
    { 
      field: 'policyNumber', 
      headerName: 'Policy Number',
      flex: 1,
      minWidth: 120,
    },
    { 
      field: 'homeState', 
      headerName: 'Home State',
      flex: 0.8,
      minWidth: 100,
    },
    { 
      field: 'transactionTypeName', 
      headerName: 'Transaction Type',
      flex: 1.2,
      minWidth: 140,
    },
    { 
      field: 'invoiceDate', 
      headerName: 'Inv Date',
      flex: 1,
      minWidth: 100,
      renderCell: (params) =>
      (
        <div>
          {(params.row.invoiceDate != null && params.row.invoiceDate != '') ? moment(params.row.invoiceDate).format("MM/DD/YYYY") : ""}
        </div>
      )
    },
    { 
      field: 'effectiveDate', 
      headerName: 'Policy Eff Date',
      flex: 1,
      minWidth: 120,
      renderCell: (params) =>
      (
        <div>
          {(params.row.effectiveDate != null && params.row.effectiveDate != '') ? moment(params.row.effectiveDate).format("MM/DD/YYYY") : ""}
        </div>
      )
    },
    { 
      field: 'policyExpDate', 
      headerName: 'Policy Exp Date',
      flex: 1,
      minWidth: 120,
      renderCell: (params) =>
      (
        <div>
          {(params.row.policyExpDate != null && params.row.policyExpDate != '') ? moment(params.row.policyExpDate).format("MM/DD/YYYY") : ""}
        </div>
      )
    },
    {
      field: 'grossPremium',
      headerName: 'Gross Premium',
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        let grossPremium = params.value || '';
        let cleanedValue = grossPremium.replace(/[,\$]/g, "").trim();

        if (cleanedValue.startsWith("(") && cleanedValue.endsWith(")")) {
          cleanedValue = "-" + cleanedValue.slice(1, -1);
        }

        const numericValue = parseFloat(cleanedValue);

        return isNaN(numericValue) ? grossPremium : `${formatPrice(numericValue.toString())}`;
      }
    },
    {
      field: 'documentUpload',
      headerName: 'Document Upload',
      flex: 1,
      minWidth: 120,
      renderCell: (params) => (
        <IconButton
        onClick={() => { UploadDocument(params.row.customerpolicyid) }}
        sx={{ 
            color: '#1976d2',
            '&:hover': {
            backgroundColor: 'transparent',
            }
        }}
        >
          <svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25M12.75 6L9 2.25M9 2.25L5.25 6M9 2.25V11.25" stroke="#162E3C" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </IconButton>
      ),
    },
  ];

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Box sx={{ width: '100%', padding: '20px' }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'flex-start',
          marginBottom: '20px'
        }}>
          {/* Left side with Welcome message */}
          <Box>
            <Typography 
              variant="h4" 
              component="h1" 
              sx={{ 
                fontSize: '28px',
                fontWeight: 'bold',
                marginBottom: '8px'
              }}
            >
              Welcome {currentUser?.userFirstName} {currentUser?.userlastName}!
            </Typography>
              
            <Typography 
              variant="body1" 
              sx={{ 
                fontSize: '16px'
              }}
            >
              Please upload your filing documents below by selecting the <strong>Document Upload</strong> button to the right of each transaction.
            </Typography>
          </Box>

          {/* Right side with Reference Sheet */}
          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center',
              gap: '8px'
            }}
          >
            <DescriptionIcon sx={{ 
              fontSize: '40px',
              color: '#000'
            }} />
            <Box>
              <Typography 
                variant="body1" 
                sx={{ 
                  fontWeight: 'bold',
                  fontSize: '14px'
                }}
              >
                REFERENCE SHEET:
              </Typography>
              <Typography 
                variant="body2"
                sx={{
                    fontSize: '14px'
                }}
              >
                Required Documents for Filing By State
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
      <div style={{ height: '60vh', width: '100%', padding: '0 20px' }}>
      <DataGrid
        getRowId={(r) => r.rowNum}
        rows={itemList}
        columns={columns}
        rowCount={totalCount}
        pageSize={pageSize}
        pagination
        paginationMode="server" // Enable server-side pagination
        onPageChange={(newPage: number) => setPageNumber(newPage + 1)} // Trigger page change
        rowsPerPageOptions={[10, 25, 50]}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        filterMode="server"
        onFilterModelChange={(newFilterModel: any) => setFilterModel(newFilterModel)} // For filtering
        sortingMode="server"
        sortingOrder={['asc', 'desc']}
        onSortModelChange={(newSortModel: any) => setSortModel(newSortModel)} // For sorting
        loading={loading} // Show loading indicator during fetch
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: "normal",
          },
          fontFamily: "'Proxima Nova', sans-serif",
          border: "0px",
          fontSize: "16px",
        }}
      />
      </div>
      <AddDocument open={openDoc} handleClose={handleClose} customerPolicyId={ID}></AddDocument>
    </Box>
  );
};