import React, { useEffect, useMemo, useState } from "react";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    LinearProgress,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import useAuth from "../context/AuthProvider";
import { Form, Formik } from "formik";
import Swal from "sweetalert2";
import { apiClient } from "../helper/api";

interface props {
    open: boolean;
    handleClose: () => void;
    customerPolicyId: any;
}

interface RequiredDocument {
    modelId: number;
    documentName: string;
}

export const AddDocument: React.FC<props> = ({ open, handleClose, customerPolicyId }) => {
    const initialValues: {} = {};
    const [loading, setLoading] = useState(false);
    const [itemList, setItemList] = useState<RequiredDocument[]>([])
    const { user } = useAuth();
    const token = user?.token;

    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

    useEffect(() => {
        if(open){
            fetchData();
        }
    }, [open])

    const fetchData = () => {
        apiClient(`/customer/getRequiredDocuments?customerPolicyId=${customerPolicyId}`, {
          method: "GET",
          headers: new Headers({
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
          }),
        })
        .then(responseJson => {
          if (!responseJson) return
    
          if (responseJson.data && responseJson.data.length > 0) {
              setItemList(responseJson.data);
              setLoading(false);
          } else {
              setItemList([]);
              setLoading(false);
          }
        })
        .catch(error => {
          setItemList([]);
          setLoading(false);
        });
    };

    const handleFileDrop = (file: File, modelId: number) => {
        setSelectedFiles((previousFiles) => {
            const fileIndex = previousFiles.findIndex((file) => {
                const regex = new RegExp(`^${modelId}__`);
                return regex.test(file.name);
            });
    
            if (fileIndex !== -1) {
                const updatedFiles = [...previousFiles];
                updatedFiles[fileIndex] = file;
                return updatedFiles;
            } else {
                return [...previousFiles, file];
            }
        });
    };

    const handleFileRemove = (index: number) => {
        setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    const handleBoxClose = () => {
        setSelectedFiles([]);
        setItemList([])
        handleClose();
    }

    return (
        <Dialog open={open} onClose={handleBoxClose} fullWidth maxWidth={"sm"}>
            <div className="card-body">
                <DialogTitle className="card-title">Upload Documents</DialogTitle>
            </div>
            <React.Fragment>
                <Formik
                    initialValues={initialValues}
                    validateOnChange={true}
                    onSubmit={(data, { setSubmitting }) => {
                        if (selectedFiles.length < 1) {
                            Swal.fire("Error", "Please select at least one document.", "error");
                            setSubmitting(false);
                            return;
                        }

                        const formData = new FormData();
                        selectedFiles.forEach((file, index) => {
                            formData.append(`document${index}`, file);
                        });
                        formData.append("CustomerPolicyId", customerPolicyId);
                        formData.append("data", JSON.stringify(data));

                        setLoading(true);
                        apiClient(`/customer/UploadDocuments/`, {
                            method: "POST",
                            headers: new Headers({
                                Authorization: `Bearer ${token}`,
                            }),
                            body: formData,
                        })
                            .then((res) => {
                                if (!res) return;
                                Swal.fire("Success", res.data, "info");
                            })
                            .finally(() => {
                                setSubmitting(false);
                                setLoading(false);
                                handleBoxClose();
                            });
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
                            <DialogContent>
                                <div className="card-body">
                                    {itemList.length > 0 ? itemList.map((item: RequiredDocument, index: number) => (
                                        <>
                                            <GenericStyledDropZone
                                                key={index}
                                                handleFileDrop={handleFileDrop}
                                                item={item}
                                            />
                                            <div className="mt-3">
                                                {selectedFiles.map((file, index) => (
                                                    <>
                                                        {Number(file.name.split('__')[0]) === item.modelId && (
                                                            <div
                                                                key={index}
                                                                style={{
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center",
                                                                    marginBottom: "5px",
                                                                }}
                                                            >
                                                                <span>
                                                                    {file.name.split('__')[1]?.length > 35
                                                                        ? file.name.split('__')[1].substring(0, 35) + "..."
                                                                        : file.name.split('__')[1]}
                                                                </span>
                                                                <button
                                                                    type="button"
                                                                    onClick={() => handleFileRemove(index)}
                                                                    style={{
                                                                        background: "none",
                                                                        border: "none",
                                                                        color: "red",
                                                                        cursor: "pointer",
                                                                    }}
                                                                >
                                                                    Remove
                                                                </button>
                                                            </div>
                                                        )}
                                                    </>
                                                ))}
                                            </div>
                                        </>
                                    )) : ''}
                                </div>
                                <div className="card-body">
                                    <div className="d-flex justify-content-end">
                                        <button
                                            type="submit"
                                            className="active-btn-blue me-2"
                                            disabled={isSubmitting}
                                        >
                                            Upload
                                        </button>
                                        <button
                                            className="active-btn-white"
                                            type="button"
                                            onClick={handleBoxClose}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </DialogContent>
                        </Form>
                    )}
                </Formik>
            </React.Fragment>
        </Dialog>
    );
};

function GenericStyledDropZone(props: any & RequiredDocument) {
    const { handleFileDrop, item } = props;

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        maxFiles: 10,
        accept: {
            "application/pdf": [".pdf"],
            "image/*": [],
            "application/msg": [".msg"],
            "application/word": [".word"],
        },
        // onDrop: handleFileDrop,
        onDrop: (files) => {
            const newFileName = `${item.modelId}__${files[0].name}`; // Example: Add 'Modified_' prefix
            const modifiedFile = new File([files[0]], newFileName, {
                type: files[0].type,
                lastModified: files[0].lastModified,
            });

            // Pass modified files to the parent handler
            handleFileDrop(modifiedFile, item.modelId);
        },
    });

    const focusedStyle = {
        borderColor: "#2196f3",
    };

    const acceptStyle = {
        borderColor: "#00e676",
    };

    const rejectStyle = {
        borderColor: "#ff1744",
    };

    const baseStyle = {
        flex: 1,
        display: "flex",
        alignItems: "center",
        padding: "10px",
        borderWidth: 1,
        borderRadius: 8,
        borderColor: "#162E3C80",
        borderStyle: "dashed",
        backgroundColor: "rgba(22, 46, 60, 0.10)",
        color: "#162E3C",
        outline: "none",
        transition: "border .24s ease-in-out",
    };

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    return (
        <div className="box-style col-lg-12">
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} name={"diligentdocument"} multiple />
                <p
                    style={{
                        fontSize: "16px",
                        color: "#162E3C",
                        fontWeight: "600",
                    }}
                >
                    Drop {item.documentName} Documents for Filing
                </p>
            </div>
        </div>
    );
}
