import React, { useEffect, useMemo, useState,useRef } from "react";
import { Box } from "@mui/material";
import {
    DataGrid,
    GridColDef,
    GridSelectionModel,
    GridRowId,
} from "@mui/x-data-grid";
import { RiExpandUpDownLine } from "react-icons/ri";
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    LinearProgress,
    Link,
    SelectChangeEvent,
} from "@mui/material";
import moment from "moment";
import useAuth from "../../context/AuthProvider";
import formatPrice from "../../helper/currencyFormatter";
import { StateReportModel_Resp, StateReportModel } from "../../models/StateReportModel";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
//import { GenericStyledDropZone } from "../AddEdit/AddEditResubmitSingleDocument";
import { useDropzone } from "react-dropzone";
import { config } from '../../Constants';
import Swal from "sweetalert2";
import { minHeight } from "@mui/system";
import {GridFilterItem, GridFilterModel, GridLinkOperator, GridSortModel } from "@mui/x-data-grid-pro";
import { AddEditStateReport } from "../AddEdit/AddEditStateReport";
var formData = new FormData();
interface Iprops {
    ReportModuleCL: StateReportModel_Resp;
    fetchDataMain: (
        action: string,
        pageSize: number,
        currentPage: number,
        field: string,
        sort: string,
        completedPage?: boolean
    ) => void;
    selectedButton: number,
    setSelectedRows: React.Dispatch<React.SetStateAction<StateReportModel[] | undefined>>
    setCurrentPage:  React.Dispatch<React.SetStateAction<number>>
    setPageSize:  React.Dispatch<React.SetStateAction<number>>
    _currentPage: number
    _pageSize: number
    completedPage: boolean
    selectedRows: StateReportModel[] | undefined
    debouncedOnFilterChange: ((action: string, filterModel: GridFilterModel, pageSize: number, pageNumber: number, field: string, sort: string, isCompletedPage?: boolean) => void)
}

export const StateReportList: React.FC<Iprops> = ({
    ReportModuleCL,
    fetchDataMain,
    selectedButton,
    setSelectedRows,
    setCurrentPage,
    completedPage,
    selectedRows,
    debouncedOnFilterChange
}) => {

    const { user } = useAuth();
    const token = user?.token;
    const [_pageSize, setPageSize] = React.useState(25);
    const fetchData = (customerPolicyId: number) => {

    }

    const initialLBData: StateReportModel_Resp = {
        stateReportModel_Respdata: [],
        largeReport: undefined,
        completedPage: undefined,
        totalCount: 0,
        pageCount: 0,
        pageSize: 25,
        currentPage: 0
       
    };
    const handleEdit = (ILobData: StateReportModel_Resp) => {

        setFormData(ILobData);
        setOpenEdit(true);

    };

    const [loading, setloading] = React.useState(false);
    const [Open, setOpen] = React.useState(false);
    const [OpenEdit, setOpenEdit] = React.useState(false);
    const [openDoc, setOpenDoc] = React.useState(false);
    const [showDoc, setShowDoc] = React.useState(false);
    const [ID, setID] = React.useState();
    const [Formdata, setFormData] = React.useState(initialLBData);
    const [dataList, setDataList] = React.useState<StateReportModel[]>([]); 
    const PloicyNum = React.useRef("");
    const insName = React.useRef("");
    const effectiveDateEd = React.useRef("");
    const effectiveDateSt = React.useRef("");
    const stateData = React.useRef(0);
    const customerData = React.useRef(0);
    const taxData = React.useRef("");
    const statusData = React.useRef("");
    const sortModelRef = useRef<GridSortModel>([]);


    useEffect(()=>{
        const SetData = ()=>{ 
            const newArray: StateReportModel[] | ((prevState: never[]) => never[]) = []
            ReportModuleCL.stateReportModel_Respdata.map((val,index)=>{
                const obj = val
                obj.rowNum = index
                newArray.push(obj)
            }

    )
    setDataList(newArray)

    }
        SetData();
    },[ ReportModuleCL.stateReportModel_Respdata])
    
    const handleClose = () => {
        setOpen(false);
        setOpenDoc(false);
        setShowDoc(false);
        setOpenEdit(false);
        // fetchData();
    };
    
    function OpneShowDocument(id: any) {

        setID(id);
        setShowDoc(true);
        fetchData(id);
    }

    const handleSubmit = () => {
        const action = "getStateReport";
        fetchDataMain(action, 50, 1,"","",completedPage);
        setOpenEdit(false);
    };
    const handleClickOpen = () => {
        setFormData(initialLBData);
        setOpen(true);
    };

    //const columns: GridColDef[] = [
        const columns: GridColDef[] = selectedButton === 1 ? [
        {
            field: "stateName", headerName: "State", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 0.5,
        },

        {
            field: "name", headerName: "ReportName", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: .8,
        },

        {
            field: "period", headerName: "Period", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: .7,
        },
        {
            field: "dueDate", headerName: "DueDate"  , type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ),
            renderCell: (params) =>
                (
                  <div>
                    {(params.row.dueDate != null && params.row.dueDate != '') ? moment(params.row.dueDate).format("MM/DD/YYYY") : ""}
                  </div>
                ), align: "center"
        }, 
        {
            field: "payment", headerName: "PaymentRequired", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 0.5,
        },
        {
            field: "report", headerName: "ReportRequired", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: .8,
        },
        {
            field: "paymentType", headerName: "PaymentType", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: .6,
        },
        {
            field: "wetSignature", headerName: "WetSignatureRequired", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: .9,
        },
        {
            field: "notaryStamp", headerName: "NotaryStampRequired", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 0.4,
        },
        {
            field: "licensee", headerName: "Licensee", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: .9,
        },
        {
            field: "licenseNumber", headerName: "LicenseNumber", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: .9,
        },

        {
            field: "client", headerName: "Client", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 0.8,
        },
        {
            field: "mainLicensee", headerName: "MainLicensee", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 0.4,
        },
        {
            field: "zeroIndividual", headerName: "ZeroIndividual", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 0.4,
        },
        {
            field: "zeroEntity", headerName: "ZeroEntity", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 0.4,
        },
        {
            field: "status", headerName: "Status", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 0.4,
        },

        {
            field: "largeReport", headerName: "LargeReport", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 0.6,
        },
        {
            field: "notes", headerName: "NotesToClient", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 0.6,
        },
        {
            field: "taxAmount", headerName: "TaxReported", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 0.6,
            renderCell: (params) => {
                const value = params.value || '0'; // Default to '0' if value is null or undefined
                return (
                    <Box display="flex" alignItems="left" justifyContent="left">

                        {formatPrice(value)}

                    </Box>
                )
            }
        },
    {
        field: 'stateRule', flex: 0.4, headerName: "Edit", hideSortIcons: true,
       
         align: 'center', headerAlign: 'center', renderCell: (params) => (<div>
           <IconButton
        color="primary"
        onClick={() => { handleEdit(params.row) }}
    >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 3C17.2626 2.73735 17.5744 2.52901 17.9176 2.38687C18.2608 2.24473 18.6286 2.17157 19 2.17157C19.3714 2.17157 19.7392 2.24473 20.0824 2.38687C20.4256 2.52901 20.7374 2.73735 21 3C21.2626 3.26264 21.471 3.57444 21.6131 3.9176C21.7553 4.26077 21.8284 4.62856 21.8284 5C21.8284 5.37143 21.7553 5.73923 21.6131 6.08239C21.471 6.42555 21.2626 6.73735 21 7L7.5 20.5L2 22L3.5 16.5L17 3Z" stroke="#02A4E3" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    </IconButton>


        </div>)
    },
    //];
    ] : selectedButton === 2 ? [ 
        {
            field: "stateName", headerName: "State", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 0.5,
        },

        {
            field: "name", headerName: "ReportName", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: .8,
        },

        {
            field: "period", headerName: "Period", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: .7,
        },
        {
            field: "dueDate", headerName: "DueDate", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: .7,
        },
        {
            field: "payment", headerName: "PaymentRequired", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 0.5,
        },
        {
            field: "report", headerName: "ReportRequired", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: .8,
        },
        {
            field: "paymentType", headerName: "PaymentType", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: .6,
        },
        {
            field: "wetSignature", headerName: "WetSignatureRequired", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: .9,
        },
        {
            field: "notaryStamp", headerName: "NotaryStampRequired", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 0.4,
        },
        {
            field: "licensee", headerName: "Licensee", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: .9,
        },
        {
            field: "licenseNumber", headerName: "LicenseNumber", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: .9,
        },

        {
            field: "client", headerName: "Client", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 0.8,
        },
        // {
        //     field: "DateFinalReportSentToClient", headerName: "DateCompleted", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
        //         <div>
        //             <span className="table-card-title" >{params.colDef.headerName}</span>
        //             <RiExpandUpDownLine />
        //         </div>
        //     ), align: "center", flex: 0.6,
        // },
        {
            field: "dateFinalReportSentToClient", headerName: "DateCompleted", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ),
            renderCell: (params) =>
                (
                  <div>
                    {(params.row.dateFinalReportSentToClient != null && params.row.dateFinalReportSentToClient != '') ? moment(params.row.dateFinalReportSentToClient).format("MM/DD/YYYY") : ""}
                  </div>
                ), align: "center", flex: 0.8,
        },      
        {
            field: "mainLicensee", headerName: "MainLicensee", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 0.4,
        },
        {
            field: "zeroIndividual", headerName: "ZeroIndividual", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 0.4,
        },
        {
            field: "zeroEntity", headerName: "ZeroEntity", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 0.4,
        },
        {
            field: "status", headerName: "Status", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 0.6,
        },

        {
            field: "largeReport", headerName: "LargeReport", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 0.6,
        },
        {
            field: "notes", headerName: "NotesToClient", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 0.6,
        },
        {
            field: "taxAmount", headerName: "TaxReported", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 0.6,
            renderCell: (params) => {
                const value = params.value || '0'; // Default to '0' if value is null or undefined
                return (
                    <Box display="flex" alignItems="left" justifyContent="left">

                        {formatPrice(value)}

                    </Box>
                )
            }
        }, 
    ]: [];
    const onRowsSelectionHandler = (ids: GridSelectionModel) => {
      const selectedIDs = new Set(ids)
      const selectedRowData = ReportModuleCL.stateReportModel_Respdata.filter(
        (row) => selectedIDs.has(row?.rowNum ?? "")
      )
      setSelectedRows(selectedRowData)
    }

    const onFilterChange = React.useCallback((filterModel: GridFilterModel) => {
        var field = sortModelRef.current[0]?.field ?? '';
        var sort = sortModelRef.current[0]?.sort ?? '';
        debouncedOnFilterChange("getStateReport",filterModel, 50, 1, field, sort, completedPage);
    }, [completedPage]);
    
    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        sortModelRef.current = sortModel;
        const action = "getStateReport";
        fetchDataMain(action, 50, 1, sortModel[0].field, (sortModel[0].sort == undefined || sortModel[0].sort == null) ? '' : sortModel[0].sort, completedPage);
    }, [completedPage]);

    const handleNextPreviousPage = (newPage: number) => {

        setloading(true);
        var field = sortModelRef.current[0]?.field ?? '';
        var sort = sortModelRef.current[0]?.sort ?? '';
        setCurrentPage(newPage);
        const action = "getStateReport";
        // fetchDataMain(action, 50, newPage, field, sort);
        setloading(false);
    };

    const handlePageSize = (newPageSize: number) => {
        var field = sortModelRef.current[0]?.field ?? '';
        var sort = sortModelRef.current[0]?.sort ?? '';
        setPageSize(newPageSize);
        const action = "getStateReport";
        // fetchDataMain(action, newPageSize, 1, field, sort);
    };

    function EditToolbar() {
        return (
            <React.Fragment>
                <div style={{ position: "fixed", top: "0", left: "0", zIndex: "9990", width: "100%" }}>
                    {loading && <LinearProgress color="info" sx={{ backgroundColor: "#132D3D" }} />}
                </div>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div style={{ height: "58vh", overflowX: "scroll" }}>
                <DataGrid
                    getRowId={(row) => row?.rowNum}
                    rows={dataList}
                    columns={columns}
                    pageSize={_pageSize}
                    pagination
                    checkboxSelection={selectedButton === 1 || selectedButton === 2}
                    selectionModel={selectedRows?.map((item) => item.rowNum).filter((rowNum): rowNum is Exclude<typeof rowNum, undefined> => rowNum !== undefined) || []}
                    onSelectionModelChange={
                        (newRowSelectionModel) => {
                            onRowsSelectionHandler(newRowSelectionModel)
                          }
                      }
                    rowCount={ReportModuleCL.totalCount}
                    rowsPerPageOptions={[25, 50, 100]}
                    paginationMode="client"
                    sortingMode="server"
                    sortingOrder={['desc', 'asc']}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    onSortModelChange={handleSortModelChange}
                    onPageChange={(newPage) => handleNextPreviousPage(newPage + 1)}
                    //onPageSizeChange={(newPageSize) => handlePageSize(newPageSize)}
                    filterMode="server"
                    onFilterModelChange={onFilterChange}
                    style={{ textAlign: "center" }}
                    // checkboxSelection={
                    //     user?.userRoles.split(",").includes("Super Admin") ? true : false
                    // }
                    components={{
                        Toolbar: EditToolbar,
                    }}
                    sx={{
                        width: "170vw",
                        "& .MuiDataGrid-columnHeaderTitle": {
                            whiteSpace: "normal",
                            lineHeight: "normal",
                        },
                        fontFamily: "'Proxima Nova', sans-serif;",
                        border: "0px",
                        fontSize: "16px",

                    }}
                    experimentalFeatures={{ newEditingApi: true }}
                    columnVisibilityModel={{
                        paymentDate: user?.userRoles.split(",").includes("Super Admin")
                            ? true
                            : false,
                        paymentAmount: user?.userRoles.split(",").includes("Super Admin")
                            ? true
                            : false,
                        lineOfBusiness: user?.userRoles.split(",").includes("Super Admin")
                            ? true
                            : false,
                    }}
                />
                 <AddEditStateReport handleClose={handleClose} fetchDataMain={fetchDataMain} Open={OpenEdit} StateRuleMatrixCL={Formdata} handleSubmit={handleSubmit} initValues={initialLBData} isStateReport = {true}/>
            </div>

        </React.Fragment>
    );
};

export function GenericStyledDropZone(props: any) {
    const {
        getRootProps,
        getInputProps,
        acceptedFiles,
        isFocused,
        isDragAccept,
        isDragReject,

    } = useDropzone({
        maxFiles: 10,
        // accept: { 'application/pdf': ['.pdf'], 'application/word': ['.word'] },
        accept: { 'application/pdf': ['.pdf'], 'image/*': [], 'application/msg': ['.msg'], 'application/word': ['.word'] },
        onDrop:
            (files) => {
                // debugger
                formData.delete("document");

                files.map((pp, key) =>
                    formData.append("document" + key, files[key])
                )
            }
    });

    const focusedStyle = {
        borderColor: '#2196f3'
    };

    const acceptStyle = {
        borderColor: '#00e676'
    };

    const rejectStyle = {
        borderColor: '#ff1744'
    };
    const baseStyle = {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        borderWidth: 1,
        borderRadius: 8,
        borderColor: '#162E3C80',
        borderStyle: 'dashed',

        backgroundColor: 'rgba(22, 46, 60, 0.10)',
        color: '#162E3C',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    };

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const files = acceptedFiles.map
        (
            file =>
            (
                <React.Fragment >
                    <span style={{ fontSize: "14px" }}>
                        {file.name.length > 35 ? file.name.substring(0, 35) + '...' : file.name}
                    </span>
                    {/* {file.name} - {(file.size / 1024).toFixed(2)} kb */}

                </React.Fragment>
            )
        );

    return (
        <React.Fragment>
            <div className=" box-style col-lg-12">
                <div {...getRootProps({ style })}>

                    <input {...getInputProps()} name={'diligentdocument'} multiple />
                    <p style={{ fontSize: "16px", color: "#162E3C", fontWeight: "600" }}>Drop diligent search document</p>
                </div>
                <span >
                    <p style={{ height: "20px", textAlign: "start", margin: 2 }}>{files}</p>
                </span>
            </div>
        </React.Fragment>
    );
}


interface props {
    open: boolean
    handleClose: () => void,
    customerPolicyId: any
}
